"use client"

import React, { FC, Fragment } from "react"
import Link from "next/link"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslations } from "next-intl"
import { useForm } from "react-hook-form"
import { MARK_BOLD, MARK_LINK, NODE_PARAGRAPH, render, StoryblokRichtext } from "storyblok-rich-text-react-renderer"
import { z } from "zod"

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Heading,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/components"
import { UbContactUsGenericFormStoryblok } from "@/components/storyblok/component-types"

interface UbContactUsGenericFormProps {
  blok: UbContactUsGenericFormStoryblok
}

const UbContactUsGenericForm: FC<UbContactUsGenericFormProps> = ({ blok }) => {
  const t = useTranslations("storefront")

  const formSchema = z.object({
    ContactUsGenericForm_Subject: z
      .string()
      .min(2, t("forms.contact_us_generic_form.error.subject"))
      .max(128, t("forms.contact_us_generic_form.error.subject_max")),
    ContactUsGenericForm_Title: z.enum(["salutation.ms", "salutation.mr", "salutation.divers"], {
      required_error: t("forms.contact_us_generic_form.error.title"),
    }),
    ContactUsGenericForm_FirstName: z
      .string()
      .min(2, t("forms.contact_us_generic_form.error.first_name"))
      .max(64, t("forms.contact_us_generic_form.error.first_name_max")),
    ContactUsGenericForm_LastName: z
      .string()
      .min(2, t("forms.contact_us_generic_form.error.last_name"))
      .max(64, t("forms.contact_us_generic_form.error.last_name_max")),
    ContactUsGenericForm_Phone: z
      .string()
      .min(2, t("forms.contact_us_generic_form.error.phone"))
      .max(32, t("forms.contact_us_generic_form.error.phone_max")),
    ContactUsGenericForm_Email: z.string().email(t("forms.contact_us_generic_form.error.email")),
    ContactUsGenericForm_CompanyName: z
      .string()
      .min(2, t("forms.contact_us_generic_form.error.company_name"))
      .max(64, t("forms.contact_us_generic_form.error.company_name_max")),
    ContactUsGenericForm_Comments: z.string().max(256, t("forms.contact_us_generic_form.error.comments_max")),
  })

  // form definition
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ContactUsGenericForm_Subject: "",
      ContactUsGenericForm_Title: undefined,
      ContactUsGenericForm_FirstName: "",
      ContactUsGenericForm_LastName: "",
      ContactUsGenericForm_Phone: "",
      ContactUsGenericForm_Email: "",
      ContactUsGenericForm_CompanyName: "",
      ContactUsGenericForm_Comments: "",
    },
  })

  // submit handler
  function onSubmit(values: z.infer<typeof formSchema>) {
    console.log(values)
  }

  const renderedRichText = (richtext: StoryblokRichtext | unknown) => {
    return render(richtext, {
      markResolvers: {
        [MARK_BOLD]: (children) => <span className={"font-bold"}>{children}</span>,
        [MARK_LINK]: (children, { linktype, href, target, uuid, custom }) => {
          if (linktype === "story" && href) {
            return (
              <Link className={"text-primary"} href={href} target={target}>
                {children}
              </Link>
            )
          }
          if (linktype === "url" && href) {
            return (
              <a
                className={"text-primary"}
                href={href}
                target={target}
                rel={target === "_blank" ? "noopener noreferrer" : undefined}
              >
                {children}
              </a>
            )
          }
          return (
            <a className={"text-primary"} href={"#"}>
              {children}
            </a>
          )
        },
      },
      nodeResolvers: {
        [NODE_PARAGRAPH]: (children) => {
          return <Fragment>{!children ? <br /> : <>{children}</>}</Fragment>
        },
      },
    })
  }

  return (
    <>
      {blok.title && (
        <Heading element={"h3"} marginBottom={"6"}>
          {blok.title}
        </Heading>
      )}
      <p className="mb-8">{blok.description}</p>
      <p className={"mb-6 text-sm text-muted-foreground"}>
        <span className={"text-primary"}>*</span> {t("forms.contact_us_generic_form.required")}
      </p>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <input type="hidden" name="ContactUsGenericForm_Topic" value={blok.topic} />
          <fieldset className="mb-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
            <FormField
              control={form.control}
              name="ContactUsGenericForm_Subject"
              render={({ field }) => (
                <FormItem className={"lg:col-span-1"}>
                  <FormControl>
                    <Input {...field} placeholder={t("forms.contact_us_generic_form.placeholder.subject")} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="ContactUsGenericForm_Title"
              render={({ field }) => (
                <FormItem className={"lg:col-span-1"}>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder={t("forms.contact_us_generic_form.placeholder.title")} />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="salutation.ms">{t("salutation.ms")}</SelectItem>
                      <SelectItem value="salutation.mr">{t("salutation.mr")}</SelectItem>
                      <SelectItem value="salutation.divers">{t("salutation.divers")}</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="ContactUsGenericForm_FirstName"
              render={({ field }) => (
                <FormItem className={"lg:col-span-1"}>
                  <FormControl>
                    <Input {...field} placeholder={t("forms.contact_us_generic_form.placeholder.first_name")} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="ContactUsGenericForm_LastName"
              render={({ field }) => (
                <FormItem className={"lg:col-span-1"}>
                  <FormControl>
                    <Input {...field} placeholder={t("forms.contact_us_generic_form.placeholder.last_name")} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="ContactUsGenericForm_Phone"
              render={({ field }) => (
                <FormItem className={"lg:col-span-1"}>
                  <FormControl>
                    <Input type={"tel"} {...field} placeholder={t("forms.contact_us_generic_form.placeholder.phone")} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="ContactUsGenericForm_Email"
              render={({ field }) => (
                <FormItem className={"lg:col-span-1"}>
                  <FormControl>
                    <Input
                      type={"email"}
                      {...field}
                      placeholder={t("forms.contact_us_generic_form.placeholder.email")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="ContactUsGenericForm_CompanyName"
              render={({ field }) => (
                <FormItem className={"lg:col-span-1"}>
                  <FormControl>
                    <Input {...field} placeholder={t("forms.contact_us_generic_form.placeholder.company_name")} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="ContactUsGenericForm_Comments"
              render={({ field }) => (
                <FormItem className={"lg:col-span-1"}>
                  <FormControl>
                    <Input {...field} placeholder={t("forms.contact_us_generic_form.placeholder.comments")} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </fieldset>
          <p className="mb-8">{renderedRichText(blok.legalconsent_notification)}</p>
          <p className={"mb-6 text-sm"}>{renderedRichText(blok.captcha_hint)}</p>
          <Button type="submit" size={"sm"} className={"float-right mb-6"}>
            {t("forms.contact_us_generic_form.send")}
          </Button>
        </form>
      </Form>
    </>
  )
}

export default UbContactUsGenericForm
