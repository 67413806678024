"use client"

import { useUbStorefrontState } from "@/store/ubStorefrontState"

import { CardPrices, determineCardPrices } from "@/lib/price-utils"
import { cn } from "@/lib/utils"

export default function ProductCardDiscount(props: { prices; variant?: string }) {
  const { isB2BCustomer } = useUbStorefrontState()

  const cardPrices: CardPrices = determineCardPrices(isB2BCustomer, props.prices)
  return (
    <div
      className={cn("absolute left-4 top-4 size-16 rotate-[342deg] bg-transparent", {
        "left-2 top-2 size-8": props.variant === "landscape",
      })}
    >
      {cardPrices && cardPrices.discountPercent > 0 ? (
        <div className={"flex size-full items-center justify-center rounded-full bg-primary text-primary-foreground"}>
          <p
            className={cn("text-lg font-bold", {
              "text-xs": props.variant === "landscape",
            })}
          >
            {cardPrices.discountPercentFormatted}
          </p>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}
