"use client"

import { addArticleToCurrentBasketAction, determineCrosssellingProductsAction } from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { ProductPriceContainerCtnrROV1 } from "@ub/pricing-client"
import { ShoppingCart } from "lucide-react"
import { useTranslations } from "next-intl"

import { ProductData } from "@/lib/storefront/product/product"
import { Button } from "@/components/ui/components"

export default function ProductCardAddToBasket(props: {
  articleIdToAdd: string
  productData: ProductData | undefined
  prices: ProductPriceContainerCtnrROV1 | undefined
}) {
  const {
    setAddToBasketDialogVisible,
    setProductDataAddedToBasket,
    setBasketData,
    setCrosssellingProductDataAddedToBasket,
    setProductDataAddedToBasketPrices,
    setIsLoading,
  } = useUbStorefrontState()
  const t = useTranslations("storefront")

  async function addToBasket() {
    if (props.articleIdToAdd) {
      addArticleToCurrentBasketAction(props.articleIdToAdd, props.productData?.stepOrderQuantity ?? 1).then(
        (basketData) => {
          setBasketData(basketData)
        }
      )

      const crosssellingProducts = await determineCrosssellingProductsAction(props.articleIdToAdd)
      setCrosssellingProductDataAddedToBasket(crosssellingProducts)
    }
  }

  function addToBasketAndShowDialog() {
    setIsLoading(true)
    addToBasket().then(() => {
      setProductDataAddedToBasket(props.productData)
      setProductDataAddedToBasketPrices(props.prices)
      setIsLoading(false)
      setAddToBasketDialogVisible(true)
    })
  }

  return (
    <Button
      aria-label={t("product.add_to_basket")}
      size={"productCardIcon"}
      onClick={(e) => {
        e.preventDefault()
        addToBasketAndShowDialog()
      }}
    >
      <ShoppingCart className={"size-4"} />
    </Button>
  )
}
