"use client"

import React from "react"
import { ChevronDownIcon } from "lucide-react"

export function UbGridShowMoreButton(props: {
  showMoreButtonText: string | undefined
  showLessButtonText: string | undefined
}) {
  return (
    // This is a hidden input of type checkbox that hold the checked state wich can be used by css to show or hide content
    // Its hidden when screen is wider than md
    <label className="peer block w-full rounded-full bg-primary px-4 py-2 text-center font-bold text-primary-foreground md:hidden">
      <div className={"flex items-center justify-center "}>
        <input
          type="checkbox"
          className="peer hidden"
          onChange={(e) => {
            // If needed we can interact with window / document here e.g. scrollTo or similar
            // console.dir(e)
          }}
        />
        <ChevronDownIcon className={"h-8 peer-checked:rotate-180"} />
        <p className={"block peer-checked:hidden"}>{props.showMoreButtonText}</p>
        <p className={"hidden peer-checked:block"}>{props.showLessButtonText}</p>
      </div>
    </label>
  )
}
